import { VaultsConfig } from './types'

const vaults: VaultsConfig[] = [
  {
    tokenSymbol: 'WBNB',
    tokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    stakingContract: '0x88979e692F5eE9C6a9bC284bfa81c842b5E375e3',
  },
  {
    tokenSymbol: 'BTCB',
    tokenAddress: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    stakingContract: '0xf43501fc80335bEB86a98DAddc4061F678fF4f00',
  },
  {
    tokenSymbol: 'ETH',
    tokenAddress: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    stakingContract: '0x8B6dB3A22Ec606d04b2bA6322E5b754Eebc226e1',
  },
  {
    tokenSymbol: 'USDT',
    tokenAddress: '0x55d398326f99059ff775485246999027b3197955',
    stakingContract: '0xd94015f978717A28728C50128704A3425fA031E8',
  },
  {
    tokenSymbol: 'BUSD',
    tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    stakingContract: '0x40F0D0EdF523B1C6593B63266fF2aBBb218A6329',
  },
]

export default vaults
