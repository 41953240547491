import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import multicall from 'utils/multicall'
import vaultsConfig from 'config/constants/vaults'
import axios from "axios";


const CHAIN_ID = process.env.REACT_APP_CHAIN_ID
const PRICE_API = `https://api.pancakeswap.info/api/v2/tokens/`
const fetchVaults = async () => {
  const data = await Promise.all(
    vaultsConfig.map(async (farmConfig) => {
      const calls = [
        // Balance of LP tokens in the master chef contract
        {
          address: farmConfig.tokenAddress,
          name: 'balanceOf',
          params: [farmConfig.stakingContract],
        },

        {
          address: farmConfig.tokenAddress,
          name: 'decimals',
        },
      ]

      const [tokenBalanceStaked, stakingTokenDecimal] = await multicall(erc20, calls)
      let price;
      const totalStaked = new BigNumber(tokenBalanceStaked)

      try{
        const resp = await axios.get(`${PRICE_API}${farmConfig.tokenAddress}`);
        price = resp.data.data.price;
        
      }catch(err){
        console.log("werere",err)
      }

      let tvl;

      if(price){
        tvl = (Number(price)*(totalStaked.toNumber()/1e18)).toFixed(2)
      }
      
      console.log("priceee",price,tvl)



      return {
        ...farmConfig,
        totalStaked:totalStaked.toJSON(),
        stakingTokenDecimal: new BigNumber(stakingTokenDecimal).toJSON(),
        price,
        tvl

      }
    }),
  )
  return data
}

export default fetchVaults
