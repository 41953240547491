import BigNumber from 'bignumber.js'
import { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useRefresh from 'hooks/useRefresh'

import { fetchVaultsPublicDataAsync, fetchPoolsUserDataAsync } from './actions'
import { State, Farm, Pool, Vault } from './types'
import { QuoteToken } from '../config/constants/types'

const ZERO = new BigNumber(0)

export const useFetchPublicData = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchVaultsPublicDataAsync())
    // dispatch(fetchPoolsPublicDataAsync())
  }, [dispatch, slowRefresh])
}

// Farms

export const useFarms = (): Farm[] => {
  const farms = useSelector((state: State) => state.farms.data)
  return farms
}

export const useVaults = (): Vault[] => {
  const vaults = useSelector((state: State) => state.vaults.data)
  console.log('auktsss', vaults)
  return vaults
}

export const useFarmFromPid = (pid): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))
  return farm
}

export const useVaultFromContract = (contract): Vault => {
  const vault = useSelector((state: State) => state.vaults.data.find((f) => f.stakingContract === contract))
  return vault
}

export const useFarmFromSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
  return farm
}

export const useFarmUser = (pid) => {
  const farm = useFarmFromPid(pid)

  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : new BigNumber(0),
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : new BigNumber(0),
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : new BigNumber(0),
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : new BigNumber(0),
  }
}

export const useVaultUser = (contract) => {
  const farm = useVaultFromContract(contract)

  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : new BigNumber(0),
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : new BigNumber(0),
    depositBalance: farm.userData ? new BigNumber(farm.userData.depositBalance) : new BigNumber(0),
    finalBalance: farm.userData ? new BigNumber(farm.userData.finalBalance) : new BigNumber(0),
    userPenalty: farm.userData ? new BigNumber(farm.userData.userPenalty) : new BigNumber(0),
    depositCheckpoint: farm.userData ? Number(farm.userData.depositCheckpoint) : Number(0),
    pentaltyTracker: farm.userData ? Number(farm.userData.pentaltyTracker) : Number(0),
    refferralInfo: {
      referrer: farm.userData && farm.userData.refferralInfo ? farm.userData.refferralInfo.referrer : '',
      referEarnings:
        farm.userData && farm.userData.refferralInfo
          ? new BigNumber(farm.userData.refferralInfo.referEarnings)
          : new BigNumber(0),
      referCounts:
        farm.userData && farm.userData.refferralInfo ? Number(farm.userData.refferralInfo.referCounts) : Number(0),
    },
  }
}

// Pools

export const usePools = (account): Pool[] => {
  const { fastRefresh } = useRefresh()
  const dispatch = useDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const pools = useSelector((state: State) => state.pools.data)
  return pools
}

export const usePoolFromPid = (sousId): Pool => {
  const pool = useSelector((state: State) => state.pools.data.find((p) => p.sousId === sousId))
  return pool
}

// Prices

export const usePriceBnbBusd = (): BigNumber => {
  const pid = 2 // BUSD-BNB LP
  const farm = useFarmFromPid(pid)
  return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO
}

export const usePriceCakeBusd = (): BigNumber => {
  // const pid = 1 // CAKE-BNB LP
  // const bnbPriceUSD = usePriceBnbBusd()
  // const farm = useFarmFromPid(pid)
  // return farm.tokenPriceVsQuote ? bnbPriceUSD.times(farm.tokenPriceVsQuote) : ZERO
  const pid = 0 // EGG-BUSD LP
  const farm = useFarmFromPid(pid)
  return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO
}

export const useTotalValue = (): BigNumber => {
  const vaults = useVaults()
  let value = new BigNumber(0)
  for (let i = 0; i < vaults.length; i++) {
    const vault = vaults[i]
    console.log("1111",vault.tvl)

    value = value.plus(Number(vault.tvl))
  }
  console.log("assasasa",value.toNumber())
  return value
}
