import React, { useContext } from 'react'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { allLanguages } from 'config/localisation/languageCodes'
import useI18n from 'hooks/useI18n'

import { LanguageContext } from 'contexts/Localisation/languageContext'
import useTheme from 'hooks/useTheme'
import { usePriceCakeBusd } from 'state/hooks'
import { Menu as UikitMenu } from '@pancakeswap-libs/uikit'
import config from './config'

const Menu = (props) => {
  const { account, connect, reset } = useWallet()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const cakePriceUsd = usePriceCakeBusd()
  const TranslateString = useI18n()

  const localConfig = [
    // {
    //   label: TranslateString(10006, 'Home'),
    //   icon: 'HomeIcon',
    //   href: '/',
    // },
    {
      label: TranslateString(10007, 'HodlerPools'),
      icon: 'FarmIcon',
      href: '', //  '/',
    },
    {
      label: TranslateString(10008, 'Audit'),
      icon: 'AuditUpdatedIcon',
      href: 'https://paladinsec.co/projects/safegains-finance/', //  'https://www.goosedefi.com/files/hackenAudit.pdf',
    },
    {
      label: TranslateString(10009, 'About Us'),
      icon: 'AboutIcon',
      href: 'https://safegains.finance/', //  'https://certik.org/projects/goose-finance',
    },
  ]

  return (
    <UikitMenu
      account={account}
      login={connect}
      logout={reset}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage && selectedLanguage.code}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      cakePriceUsd={0}
      links={localConfig}
      priceLink="https://www.coingecko.com/en/coins/goose-finance"
      {...props}
    />
  )
}

export default Menu
