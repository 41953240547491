import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/safegains.json'
import multicall from 'utils/multicall'
import vaultsConfig from 'config/constants/vaults'
import axios from "axios";






const BIT_QUERY =`
{
  ethereum(network:bsc) {
    smartContractCalls(
      smartContractAddress: {is: "0x04341f9b0b8CDB294FE0A3CC34f6930c4E3665C1"}
      smartContractMethod: {is: "recordReferralCommission"}
      options: {desc: "block.height",limit:1000000000}
    ) {
      block {
        height
      }
      caller {
        address
      }
      transaction {
        hash
      }
     
      arguments {
        argument
        value
      }
    }
  }
}


`


export const fetchVaultUserAllowances = async (account: string) => {
  const calls = vaultsConfig.map((farm) => {
    const lpContractAddress = farm.tokenAddress
    return { address: lpContractAddress, name: 'allowance', params: [account, farm.stakingContract] }
  })

  const rawLpAllowances = await multicall(erc20ABI, calls)
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })
  return parsedLpAllowances
}

export const fetchVaultUserTokenBalances = async (account: string) => {
  const calls = vaultsConfig.map((farm) => {
    const lpContractAddress = farm.tokenAddress
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}

export const fetchVaultUserDepositBal = async (account: string) => {
  const calls = vaultsConfig.map((farm) => {
    return {
      address: farm.stakingContract,
      name: 'deposits',
      params: [account],
    }
  })

  const rawBal = await multicall(masterchefABI, calls)
  const parseBal = rawBal.map((earnings) => {
    return new BigNumber(earnings).toJSON()
  })
  return parseBal
}

export const fetchVaultUserFinalBal = async (account: string) => {
  const calls = vaultsConfig.map((farm) => {
    return {
      address: farm.stakingContract,
      name: 'finalBalance',
      params: [account],
    }
  })

  const rawBal = await multicall(masterchefABI, calls)
  const parseBal = rawBal.map((earnings) => {
    return new BigNumber(earnings).toJSON()
  })
  return parseBal
}

export const fetchVaultPenaltyBal = async (account: string) => {
  const calls = vaultsConfig.map((farm) => {
    return {
      address: farm.stakingContract,
      name: 'getPenalty',
      params: [account],
    }
  })

  const rawBal = await multicall(masterchefABI, calls)
  const parseBal = rawBal.map((earnings) => {
    console.log('sasaasas', earnings[0][1])
    return new BigNumber(earnings[0][1]._hex).toJSON()
  })
  return parseBal
}

export const fetchDepositCheckpoint = async (account: string) => {
  const calls = vaultsConfig.map((farm) => {
    return {
      address: farm.stakingContract,
      name: 'depositCheckpoint',
      params: [account],
    }
  })

  const rawBal = await multicall(masterchefABI, calls)
  const parseBal = rawBal.map((earnings) => {
    return new BigNumber(earnings).toJSON()
  })
  return parseBal
}


export const fetchMyReferralCommission = async (account: string) => {

  const myRefs = [];
  const resp = await axios({
    url: 'https://graphql.bitquery.io',
    method: 'post',
    headers: {
      'X-API-key': `BQYjSbD25SqE8OoUXCoJLIBphZVP7edx` 
    },
    data: {
      query: BIT_QUERY,
      variables:"{}"
    }
    

  })

  // console.log("sdsdsd",resp.data)
  const data = resp.data.data.ethereum.smartContractCalls
  const vaultsRefs = []

  data.map((item) => {
    if(item.arguments[0].value.toLowerCase() === account.toLowerCase()){
      myRefs.push({
        vaultContract:item.caller.address,
        amount:item.arguments[1].value
      })
      const vaultContract = item.caller.address.toLowerCase();
      if(vaultsRefs[vaultContract]){
        vaultsRefs[vaultContract] += parseInt(item.arguments[1].value)
      }else{
        vaultsRefs[vaultContract] =  parseInt(item.arguments[1].value)
      }

       console.log("rfeded",vaultsRefs[vaultContract])
    }
    return null
  })

  const finalData = []

  vaultsConfig.map((farm) => {
    let earning = vaultsRefs[farm.stakingContract.toLowerCase()];
    if(!earning){
      earning = 0;
    }
    finalData.push(earning);
    return null
  
  })


  // for(let item of data){
  //   if(item.argument[0].value.toLowerCase() === account.toLowerCase()){
  //     myRefs.push({
  //       vaultContract:item.caller.address,
  //       amount:item.argument[1].value
  //     })
  //   }
  // }
  console.log("resp.1112",finalData)
  return finalData
}

export const fetchPenaltyTracker = async (account: string) => {
  const calls = vaultsConfig.map((farm) => {
    return {
      address: farm.stakingContract,
      name: 'penaltyTracker',
      params: [account],
    }
  })

  const rawBal = await multicall(masterchefABI, calls)
  const parseBal = rawBal.map((earnings) => {
    return new BigNumber(earnings).toJSON()
  })
  return parseBal
}

export const fetchRefInfo = async (account: string , earnings) => {
  const calls = vaultsConfig.map((farm) => {
    return {
      address: farm.stakingContract,
      name: 'getRefInfo',
      params: [account],
    }
  })

  const rawInfo = await multicall(masterchefABI, calls)

  const parsedRefInfo = rawInfo.map((info,index) => {
    return {
      referrer: info[0],
      referEarnings: new BigNumber(earnings[index]).toJSON(),
      referCounts: new BigNumber(info[2]._hex).toJSON(),
    }
  })
  return parsedRefInfo
}
