/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import vaultsConfig from 'config/constants/vaults'
import fetchVaults from './fetchVaults'
import {
  fetchRefInfo,
  // fetchVaultUserEarnings,
  fetchDepositCheckpoint,
  fetchVaultPenaltyBal,
  fetchVaultUserFinalBal,
  fetchVaultUserAllowances,
  fetchVaultUserTokenBalances,
  fetchVaultUserDepositBal,
  fetchMyReferralCommission,
  // fetchPenaltyTracker
} from './fetchVaultUser'
import { VaultsState, Vault } from '../types'

const initialState: VaultsState = { data: [...vaultsConfig] }

export const vaultsSlice = createSlice({
  name: 'Vaults',
  initialState,
  reducers: {
    setVaultsPublicData: (state, action) => {
      const liveVaultsData: Vault[] = action.payload

      state.data = state.data.map((vault) => {
        const liveVaultData = liveVaultsData.find((f) => f.stakingContract === vault.stakingContract)

        return { ...vault, ...liveVaultData }
      })
    },
    setVaultUserData: (state, action) => {
      const { arrayOfUserDataObjects } = action.payload
      arrayOfUserDataObjects.forEach((userDataEl) => {
        const { index } = userDataEl
        state.data[index] = { ...state.data[index], userData: userDataEl }
      })
    },
  },
})

// Actions
export const { setVaultsPublicData, setVaultUserData } = vaultsSlice.actions

// Thunks
export const fetchVaultsPublicDataAsync = () => async (dispatch) => {
  const vaults = await fetchVaults()
  dispatch(setVaultsPublicData(vaults))
}
export const fetchVaultUserDataAsync = (account) => async (dispatch) => {
  console.log('fetchVaultUserDataAsync')
  const userVaultAllowances = await fetchVaultUserAllowances(account)
  const userVaultTokenBalances = await fetchVaultUserTokenBalances(account)
  const userFinalBal = await fetchVaultUserFinalBal(account)
  const userDepositBal = await fetchVaultUserDepositBal(account)
  const userPenalty = await fetchVaultPenaltyBal(account)
  const depositCheckpoint = await fetchDepositCheckpoint(account)
  const referralDetails  = await fetchMyReferralCommission(account)

  const refInfo = await fetchRefInfo(account,referralDetails)
  // const pentaltyTracker = await fetchPenaltyTracker(account);

  const arrayOfUserDataObjects = userVaultAllowances.map((vaultAllowance, index) => {
    return {
      index,
      allowance: userVaultAllowances[index],
      tokenBalance: userVaultTokenBalances[index],
      finalBalance: userFinalBal[index],
      depositBalance: userDepositBal[index],
      userPenalty: userPenalty[index],
      depositCheckpoint: depositCheckpoint[index],
      refferralInfo: refInfo[index],
      // pentaltyTracker:pentaltyTracker[index]
    }
  })

  dispatch(setVaultUserData({ arrayOfUserDataObjects }))
}

export default vaultsSlice.reducer
